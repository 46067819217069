import React, { useState } from 'react'
import { GetLandingPageStyle } from './GetStartedProductPage.style';
import DialogComponent from '../DialogComponent';

const GetLandingPage = (props) => {
    const { landingPageData } = props;
    const [openPopup, setOpenPopup] = useState(false);


    function handleClick() {
        if (landingPageData?.video_url) {
            setOpenPopup(true)
        }
        else {
            setOpenPopup(false)
        }
    };

    function convertYouTubeLink(inputUrl) {
        if (inputUrl.includes('autoplay=1')) {
          return inputUrl;  // If autoplay=1 is already present, return the URL as is
        }
        const regex = /(?:https?:\/\/(?:www\.)?youtube\.com\/(?:embed\/|v\/|watch\?v=))([^?&]+)/;
      
        const match = inputUrl.match(regex);
      
        if (match && match[1]) {
          const videoId = match[1];
          return `https://www.youtube.com/embed/${videoId}`;
        } else {
          return "Invalid YouTube URL";
        }
      }
      
      const inputUrl = landingPageData?.video_url;

      console.log("inputUrl======================",inputUrl);

      const newUrl = convertYouTubeLink(inputUrl);   


      console.log("newUrl======================",newUrl);


    return (
        <GetLandingPageStyle>
            {landingPageData &&
                <div className='main-section-landing' style={{ backgroundImage: `url('../images/AssetBlog.png')` }}>
                    <div className='container'>
                        <div className='row' style={{ display: "flex" }}>
                            <div class="media-wrap">
                                {!landingPageData?.video_url ?
                                    <div className='image-section'>
                                        <img src={`${process.env.API_URL}${landingPageData?.image}`} />
                                    </div>
                                    :
                                    <div className='video-section'>
                                        {landingPageData?.video_thumbnail &&
                                        <>
                                            <img src={`${landingPageData?.video_thumbnail}`} onClick={handleClick} />
                                            <img src="/images/youtube-log.png" className="youtube-icon" onClick={handleClick} />
                                        </>
                                        }
                                    </div>
                                }

                            </div>
                            <div class="media-content-wrap">
                                <div className='heading-section'>
                                    <h2 className='heading-sec'>{landingPageData?.heading}</h2>
                                </div>
                                <div className='heading-section'>
                                    <h3 style={{ color: "#000" }}>{landingPageData?.content}</h3>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
            <DialogComponent classFor="display_youtube" opend={openPopup} handleClose={() => {
                setOpenPopup(false)
            }} title={''}>
                <div className="youtube_video" style={{ textAlign: "center" }}>
                    <iframe style={{
                        width: "100%",
                    }} height="400" src={`${newUrl}${openPopup ? '?autoplay=1' : ''}`}  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </DialogComponent>
        </GetLandingPageStyle>
    )
}

export default GetLandingPage